/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, login, register, requestOtp } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'

const initialValues = {
  firstname: '',
  lastname: '',
  nic:'',
  phone: '',
  email: '',
  password: '',
  changepassword: '',
  address: '',
  school: '',
  class_grade: '',
  class_type: '',
  location: '',
  account_type: 'student',
  district: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  nic: Yup.string()
  .required('NIC is required')
  .matches(
    /^(\d{12}|\d{9}[Vv])$/,
    'NIC must be 12 digits or 9 digits followed by V or v'
  ),
  phone: Yup.string()
    .min(9, 'Minimum 10 symbols')
    .required('Phone is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  account_type: Yup.string()
    .required('Account type is required'),
  class_type: Yup.string()
    .required('Clas type is required'),
  class_grade: Yup.string()
    .required('Clas grade is required'),
  address: Yup.string().required('Address is required'),
  district: Yup.string()
    .required('District is required'),
  school: Yup.string()
    .required('School is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
  location: Yup.string().when('class_type', {
    is: 'physical',
    then: Yup.string().required('Location is required'),
  }),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  let navigate = useNavigate();
  const [showLocation, setShowLocation] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const res: any = await register(
          values.email,
          values.nic,
          values.phone,
          values.firstname,
          values.lastname,
          values.school,
          values.address,
          values.district,
          values.class_grade,
          values.class_type,
          values.account_type,
          values.password,
          values.location
        );
        if (res && res.data && res.data.user) {
          // navigate(`/auth/login?msg=Student registration is a success! Please login.`);
          requestOtp(res.data.user.id, res.data.user.phone);
          navigate(`/auth/validate-otp?token=${btoa(JSON.stringify(res.data.user))}`);
        } else {
          if (res && res.statusCode === 400) {
            saveAuth(undefined)
            setStatus(res.message)
            setSubmitting(false)
            setLoading(false)
            return;
          }
          saveAuth(undefined)
          setStatus('The registration details are incorrect')
          setSubmitting(false)
          setLoading(false)
        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    if (formik.values.class_type === 'physical') {
      setShowLocation(true)
    } else {
      setShowLocation(false)
    }
  }, [formik.values.class_type])

  return (
    <form
      className='form w-500 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            Login
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-2'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row'>
            <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
            <input
              placeholder='Last name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='row fv-row mb-2'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>NIC</label>
          <input
            placeholder='123456789v'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('nic')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.nic && formik.errors.nic,
              },
              {
                'is-valid': formik.touched.nic && !formik.errors.nic,
              }
            )}
          />
          {formik.touched.nic && formik.errors.nic && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.nic}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone number</label>
          <input
            placeholder='Phone number'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('phone')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.phone && formik.errors.phone },
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-2'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group School */}
      <div className='fv-row mb-2'>
        <label className='class="form-label fw-bolder text-dark fs-6'>School</label>
        <input
          placeholder='School'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('school')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.school && formik.errors.school,
            },
            {
              'is-valid': formik.touched.school && !formik.errors.school,
            }
          )}
        />
        {formik.touched.school && formik.errors.school && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.school}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group Address */}
      <div className='fv-row mb-2'>
        <label className='class="form-label fw-bolder text-dark fs-6'>Address</label>
        <input
          placeholder='Address'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('address')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.address && formik.errors.address,
            },
            {
              'is-valid': formik.touched.address && !formik.errors.address,
            }
          )}
        />
        {formik.touched.address && formik.errors.address && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.address}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Address */}
      <div className='fv-row mb-2'>
        <label className='class="form-label fw-bolder text-dark fs-6'>District</label>
        <select
          {...formik.getFieldProps('district')}
          className={clsx(
            'form-select form-select-solid form-select-lg',
            { 'is-invalid': formik.touched.district && formik.errors.district },
            {
              'is-valid': formik.touched.district && !formik.errors.district,
            }
          )}
        >
          <option value=''>Select District</option>
          <option value='Colombo'>Colombo</option>
          <option value='Gampaha'>Gampaha</option>
          <option value='Kalutara'>Kalutara</option>
          <option value='Kandy'>Kandy</option>
          <option value='Matale'>Matale</option>
          <option value='Nuwara Eliya'>Nuwara Eliya</option>
          <option value='Galle'>Galle</option>
          <option value='Gampaha'>Gampaha</option>
          <option value='Matara'>Matara</option>
          <option value='Hambantota'>Hambantota</option>
          <option value='Kilinochchi'>Kilinochchi</option>
          <option value='Mannar'>Mannar</option>
          <option value='Vavuniya'>Vavuniya</option>
          <option value='Mullaitivu'>Mullaitivu</option>
          <option value='Batticaloa'>Batticaloa</option>
          <option value='Ampara'>Ampara</option>
          <option value='Trincomalee'>Trincomalee</option>
          <option value='Kurunegala'>Kurunegala</option>
          <option value='Puttalam'>Puttalam</option>
          <option value='Anuradhapura'>Anuradhapura</option>
          <option value='Polonnaruwa'>Polonnaruwa</option>
          <option value='Badulla'>Badulla</option>
          <option value='Moneragala'>Moneragala</option>
          <option value='Ratnapura'>Ratnapura</option>
          <option value='Kegalle'>Kegalle</option>
        </select>
        {formik.touched.district && formik.errors.district && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.district}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form Class Grade */}
      <div className='fv-row mb-2'>
        <label className='class="form-label fw-bolder text-dark fs-6'>Class Grade</label>
        <select
          className='form-select form-select-solid form-select-lg'
          {...formik.getFieldProps('class_grade')}
        >
          <option value=''>Select Class Grade</option>
          <option value='al'>Advanced Level</option>
          <option value='ol'>Ordinary Level</option>
        </select>
        {formik.touched.class_grade && formik.errors.class_grade && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.class_grade}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form Class Grade */}
      <div className='fv-row mb-2'>
        <label className='class="form-label fw-bolder text-dark fs-6'>Class Type</label>
        <select
          className='form-select form-select-solid form-select-lg'
          {...formik.getFieldProps('class_type')}
        >
          <option value=''>Select Class Type</option>
          <option value='online'>Online</option>
          <option value='physical'>Physical</option>
        </select>
        {formik.touched.class_type && formik.errors.class_type && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.class_type}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {showLocation && (
        <div className='fv-row mb-2'>
          <label className='class="form-label fw-bolder text-dark fs-6'>Location</label>
          <select
            className='form-select form-select-solid form-select-lg'
            {...formik.getFieldProps('location')}
          >
            <option value=''>Select Location</option>
            <option value='aone_montana'>Aone Montana</option>
            <option value='montana_gampaha'>Montana Gampaha</option>
          </select>
          {formik.touched.location && formik.errors.location && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.location}</span>
              </div>
            </div>
          )}
        </div>
      )}

      {/* begin::Form group Address */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Account Type</label>
        <select
          className='form-select form-select-solid form-select-lg'
          {...formik.getFieldProps('account_type')}
        >
          {/* <option value=''>Select Account Type</option> */}
          <option value='student'>Student</option>
          <option value='teacher'>Teacher</option>
        </select>
        {formik.touched.account_type && formik.errors.account_type && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.account_type}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-2 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='#' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
