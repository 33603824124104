/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Link, Routes, Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

export function ChatPage() {
  return (
    <div className='text-center mb-10'>
      <h1 className='text-dark mb-3'>Support Chat</h1>
    </div>
  )
}
