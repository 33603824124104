import React, { FC, SetStateAction, Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GCP_STORAGE_BASE_URL } from '../../../config';
import { PageTitle } from '../../../_metronic/layout/core';
import { Card3 } from '../../../_metronic/partials/content/cards/Card3';
import { AccountHeader } from '../../modules/accounts/AccountHeader';
import { useAuth } from '../../modules/auth';
import { getAuth } from '../../modules/auth/core/AuthHelpers';
import { getEnClasses } from '../../modules/classes/users-list/core/_requests';

const UpdateProfileModal = React.lazy(() =>
  import('./_modals/update-profile/UpdateProfileModal').then((module) => ({ default: module.UpdateProfileModal }))
);


const DashboardPage: FC = () => {
  const auth = getAuth();

  const { logout } = useAuth();
  const [greet, setGreet] = useState<string>("Welcome Student!");
  const [classesAl, setClassesAl] = useState<any[]>([]);
  const [showUpdateProfile, setShowUpdateProfile] = useState<boolean>(true);
  const [nic, setNic] = useState<string>(auth.nic || '');
  const [classType, setClassType] = useState<string>(auth.class_type || '');
  const [location, setLocation] = useState<string>(auth.location || '');

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none');
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none');
    };
  }, []);

  useEffect(() => {
    async function func() {
      var myDate = new Date();
      var hours = myDate.getHours();
      var greet;

      if (hours < 12)
        greet = "Morning";
      else if (hours >= 12 && hours <= 17)
        greet = "Afternoon";
      else if (hours >= 17 && hours <= 24)
        greet = "Evening";

      setGreet(`Good ${greet}, ${auth.fname}!`);

      const classList = await getEnClasses({
        course_id: null,
        payment_id: null,
        date_begin: '',
        date_end: '',
        payment_status: 'approved',
        status: '',
        sort: 'DESC',
        sort_field: 'id',
        limit: 50,
        skip: 0,
      });

      if (classList.statusCode === 401) {
        logout();
      }

      if (classList.results) {
        setClassesAl(classList.results);
      }
    }
    func();
  }, [auth, logout]);

  return (
    <>
      {auth && (auth.nic == "" || auth.class_type == "" || (auth.class_type == "physical" && auth.location == '')) && <Suspense fallback={<div>Loading...</div>}>
        <UpdateProfileModal
          data={{ nic, classType, location, setNic, setClassType, setLocation }}
          show={showUpdateProfile}
          handleClose={() => setShowUpdateProfile(false)}
        />
      </Suspense>}
      {/* <div id="google_translate_element222222"></div> */}
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xxl-8'>
          <h2 style={{ fontSize: 30 }}>{greet}</h2>
        </div>
        <AccountHeader />
        <div className="separator border-3 my-10"></div>
      </div>

      <div className='row g-6 g-xl-9 mb-6'>
        <h5>Enrolled Classes</h5>
        {classesAl.map((classItem: any) => (
          <div className='col-md-6 col-xxl-4' key={classItem.course.id}>
            <Card3
              avatar={`${GCP_STORAGE_BASE_URL}${classItem.course.icon}`}
              name={classItem.course.name_en}
              job={classItem.course.description_1_en}
              avgEarnings={classItem.course.class_time}
              totalEarnings={`Rs. ${classItem.course.course_fee}`}
              class_date={classItem.course.class_date_en}
              language={classItem.course.language}
              link={`/classes/class/${classItem.course.id}?classid=${classItem.course.id}`}
              buttonText={"Go to Class"}
            />
          </div>
        ))}
      </div>
      {/* end::Row  */}
    </>
  );
}

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
}

export { DashboardWrapper };
